import React, { useState, useEffect } from 'react';

const DocumentPage = ({ srcImage, index, form, lineRef, zoomStyles, changePage, children }) => {
  const [isHorizontal, setIsHorizontal] = useState(null);

  // Check if image is horizontal as a Promise
  const checkHorizontalImage = (srcImage) => {
    return new Promise((resolve) => {
      let im = new Image();
      im.src = srcImage;
      im.onload = () => {
        resolve(im.width > im.height);
      };
    });
  };

  useEffect(() => {
    checkHorizontalImage(srcImage).then((result) => {
      setIsHorizontal(result);
    });
  }, [srcImage]);

  if (isHorizontal === null) {
    return <div></div>;
  }

  return (
    <div className={form ? "page-image-on-preview" : "page-image"}>
      <img
        ref={lineRef}
        alt="renderedImage"
        src={srcImage}
        className={`${form ? "pdf-images-on-preview" : "pdf-images"} ${isHorizontal ? "horizontal-page" : ""}`}
        id={`pdf-page-${index + 1}`}
        style={{ ...zoomStyles }}
        onClick={() => changePage(index)}
      />
      {children}
    </div>
  );
};

export default DocumentPage;