import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BatchContext } from "../../../contexts/BatchContext";
import { AdditionalSignaturesContext } from "../../../contexts/AdditionalSignaturesContext";
import { AdditionalSignaturesFinalFlow } from "../AdditionalSignaturesFinalFlow/AdditionalSignaturesFinalFlow";

export const SkipSMSAndDraw = ({ documents, emailId, setLoadingMorePages }) => {
    const [completeAdditionalSignaturesFlow, setCompleteAdditionalSignaturesFlow] = useState(false);
    const navigate = useNavigate();

    const { batchAttachedImages } = useContext(BatchContext);
    const { isSignatureBoxesFlow } = useContext(AdditionalSignaturesContext);

    useEffect(() => {
      let link = "/sign";
      if (documents.SignerDTO.AttachIdDocImages && typeof attachedImages === 'undefined' && !batchAttachedImages) {
        link = "/attach-images";
      } else {
        if (isSignatureBoxesFlow) return setCompleteAdditionalSignaturesFlow(true);
      }

      navigate(link, {
          state: {
            documents,
            emailId,
          }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>
    {completeAdditionalSignaturesFlow && <AdditionalSignaturesFinalFlow emailId={emailId} setLoadingMorePages={setLoadingMorePages} />}
    </>;
}