import React, { Fragment, useContext, useEffect } from 'react';
import "./SmallImageViewer.css";
import { DOCUMENT_PAGE_LIST } from '../../constants/GeneralConstants';
import { RenderScrollContext } from '../../contexts/RenderScrollContext';

import { AdditionalSignaturesContext } from '../../contexts/AdditionalSignaturesContext';

import { SignatureIndicator } from './../SvgComponents/SignatureIndicator/SignatureIndicator';

export const SmallImageViewer = (props) => {
  const { images } = props;

  const { page, setPage } = useContext(RenderScrollContext);

  const { signatureBoxes } = useContext(AdditionalSignaturesContext);

  const currentPage = page.value;

  useEffect(() => {
    document.querySelectorAll('.highlight-page').forEach(e => e.classList.remove('highlight-page'));
    document.getElementById(`pdf-page-${currentPage + 1}`)?.classList.add('highlight-page');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const changePage = (newPage) => {
    setPage({
      value: newPage, 
      source: DOCUMENT_PAGE_LIST});
  };

  let imagesList = images?.map((renderedImage, index) => {
    let signatureBoxesData = signatureBoxes ? signatureBoxes : [];

    signatureBoxesData = signatureBoxesData.filter((box) => box.Page === index + 1);

    const signatureBoxesOfPage = signatureBoxesData.map((box, i) => {
      return <SignatureIndicator boxData={box} key={i} />;
    });

    return (
      <div className='small-image-container' key={index}> 
        <img
          onClick={() => changePage(index, DOCUMENT_PAGE_LIST)}
          key={index}
          alt="renderedImage"
          src={`data:image/jpeg;base64,${renderedImage}`}
          className="small-image"
          id={`pdf-page-${index + 1}`}
        />
        {signatureBoxesOfPage}
        <h6 className="small-number">{index + 1}</h6>
      </div>
    );
  });

  return imagesList;
}