import { Container, Row } from 'react-bootstrap';
import './PendingDocuments.css';
import { PendingDocumentItem } from '../../components/PendingDocumentItem/PendingDocumentItem';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { HandIcon } from '../../components/SvgComponents/HandIcon/HandIcon';
import Helper from '../../util/helper';
import { isMobile } from 'react-device-detect';
import { useEffect } from 'react';

export const PendingDocuments = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const pendingDocumentsList = location.state?.pendingDocuments;
    const email = location.state?.email;
    const lang = location.state.lang;

    return (
        <Container className="container-row row-box form-view">
            <Row>
                <div className="welcome-container">
                    <HandIcon />
                    <div className="welcome-text-container">
                        <h2> {isMobile ? Helper.getTruncatedSignerName(email) : email}
                        </h2>
                        <p>
                            {lang !== 'pt' && (
                                <>
                                    {t("popup_process.pending_documents_1")}{" "}
                                </>
                            )}
                            <span className="green-text">
                                {t("popup_process.pending_documents_2", { pending: pendingDocumentsList.length })}
                            </span>{" "}
                            {t("popup_process.pending_documents_3")}
                        </p>
                    </div>
                </div>
                <hr className="welcome-separator" />
                <div className='documents-grid'>
                    {
                        pendingDocumentsList.map((document, k) => {
                            return <PendingDocumentItem item={document} key={k} />;
                        })
                    }
                </div>
            </Row>
        </Container>
    );
};