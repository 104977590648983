import { generateOAuthRequestHeaders } from "../vidCloud/generateHeaders";
import axiosInstance from "./RemoteService";

const IPService = {};

const baseURI = process.env.REACT_APP_BACKEND_URL;

IPService.getClientIP = () => {
    const httpOptions = generateOAuthRequestHeaders();

    return axiosInstance.get(`${baseURI}/ip`, httpOptions);

}

export default IPService;