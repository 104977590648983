import { useContext, useEffect, useState } from "react"
import { getEvidenceData } from "../../../util/getEvidenceData";
import EmailSignatureService from "../../../services/EmailSignatureService";
import { useNavigate } from "react-router-dom";
import { BatchContext } from "../../../contexts/BatchContext";
import PendingDocumentService from "../../../services/PendingDocumentService";
import { FIRST_LOAD } from "../../../constants/GeneralConstants";
import { ConfirmationModalSignature } from "../../Modals/ConfirmationModalSignature/ConfirmationModalSignature";

export const OTP = ({ signerGuid, otpCode, docGuid, emailId, email, setOTPDraw, setLoadingMorePages }) => {
    const { remainingBatchDocuments, removeDocumentsFromBatch } = useContext(BatchContext);
    const [showConfirmationModalSignature, setShowConfirmationModalSignature] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setShowConfirmationModalSignature(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sendSMSignature = async () => {
        setLoading(true);
        const evidenceData = await getEvidenceData();

        const body = {
            SignatureImage: '',
            SignatureRawData: '',
            OTP: otpCode,
            ...evidenceData,
        };

        EmailSignatureService.sendSign(signerGuid, body)
            .then((res) => {
                setLoading(false);
                if (res) {
                    setShowConfirmationModalSignature(false);
                    localStorage.setItem(FIRST_LOAD, "true")

                    removeDocumentsFromBatch([docGuid]);

                    if (remainingBatchDocuments > 1) {
                        navigate('/batch-success', {
                            state: {
                                emailId,
                            }
                        })
                        return;
                    }
                    PendingDocumentService.getPendingDocumentsList(emailId)
                        .then(res => {
                            const pendingDocuments = res.data;
                            setLoading(false);
                            navigate("/signature-success", {
                                state: {
                                    pendingDocuments,
                                    emailId,
                                    email
                                }
                            });
                        })
                        .catch(err => {
                            setLoading(false);
                            navigate("/signature-success", {
                                state: {
                                    pendingDocuments: [],
                                    emailId,
                                    email
                                }
                            });
                        });
                    return;
                }
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                console.log(err);
            });
        return;
    }
    return (
        <ConfirmationModalSignature showConfirmationModalSignature={showConfirmationModalSignature} setShowConfirmationModalSignature={setShowConfirmationModalSignature} handleSignature={sendSMSignature} sendingSignPetition={loading} setOTPDraw={setOTPDraw} setLoadingMorePages={setLoadingMorePages}/>
    );
}