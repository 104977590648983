import { Row } from 'react-bootstrap';
import './DNIFlow.css';
import { AttachmentButton } from '../AttachmentButton/AttachmentButton';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getEvidenceData } from '../../../util/getEvidenceData';
import { useLocation, useNavigate } from 'react-router-dom';
import EmailSignatureService from '../../../services/EmailSignatureService';
import { OTP } from '../../../components/SignMethods/OTP/OTP';
import { ArrowIcon } from '../../../components/SvgComponents/ArrowIcon/ArrowIcon';
import { BatchContext } from '../../../contexts/BatchContext';
import { ShowErrorModal } from '../../../components/Modals/ShowErrorModal/ShowErrorModal';
import { Loader } from '../../../components/Loader/Loader';
import { AdditionalSignaturesFinalFlow } from '../../../components/SignMethods/AdditionalSignaturesFinalFlow/AdditionalSignaturesFinalFlow';
import { AdditionalSignaturesContext } from '../../../contexts/AdditionalSignaturesContext';

export const DNIFlow = () => {
  const [frontAttachment, setFrontAttachment] = useState(null);
  const [backAttachment, setBackAttachment] = useState(null);

  const [sendingEvidence, setSendingEvidence] = useState(false);
  const [error, setError] = useState(null);
  const [otpFlow, setOtpFlow] = useState(false);
  const [loading, setLoading] = useState(false);

  const { batchDocuments, setBatchAttachedImages, isBatchMultisignature } = useContext(BatchContext);

  const [completeAdditionalSignaturesFlow, setCompleteAdditionalSignaturesFlow] = useState(false);

  const { isSignatureBoxesFlow } = useContext(AdditionalSignaturesContext);

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { documents, emailId, otpCode, requestId, ssiBatch, signerGuid } = location.state;
  const email = documents.SignerDTO.eMail;

  const sendEvidenceImages = async () => {
    setSendingEvidence(true);
    const evidenceData = await getEvidenceData();

    const body = {
      EvidenceIP: evidenceData.IpAddress,
      EvidenceLocation: evidenceData.Location,
      From: evidenceData.From,
      EvidenceType: 'ID_DOC_ATTACHED',
      IdDocument: {
        FrontImage: frontAttachment.split("base64,")[1],
        BackImage: backAttachment.split("base64,")[1],
      }
    };

    EmailSignatureService.sendEvidence(location.state.emailId, body)
      .then(() => {
        if (batchDocuments.length) setBatchAttachedImages(true);

        if (isSignatureBoxesFlow) return setCompleteAdditionalSignaturesFlow(true);

        if (documents.SignerDTO.SkipSigDraw) {
          setLoading(true);
          setOtpFlow(true);
          return;
        }
        navigate("/sign", {
          state: {
            documents,
            emailId,
            otpCode,
            attachedImages: true,
            requestId,
            ssiBatch,
            signerGuid
          }
        });
      })
      .catch((e) => {
        const errorMessage = t('document_id.file_size_limit_exceeded');
        if (errorMessage.includes('token')) {
          navigate("/expired-personal-code");
          return;
        }
        setError(errorMessage);
      })
      .finally(() => {
        setSendingEvidence(false);
      })
  }

  return (
    <>
      {otpFlow && <OTP signerGuid={documents.SignerDTO.SignerGUI} otpCode={otpCode} emailId={emailId} docGuid={documents.DocGUI} email={email} setLoadingMorePages={setLoading} />}
      {completeAdditionalSignaturesFlow && <AdditionalSignaturesFinalFlow otpCode={otpCode} emailId={emailId} setLoadingMorePages={setLoading} />}
      <Row className='dni-flow'>
        <p>{t('document_id.dni_instruction')} </p>
        <AttachmentButton buttonText={t('document_id.front_image')} attachmentData={frontAttachment} setAttachmentData={setFrontAttachment} />
        <AttachmentButton buttonText={t('document_id.back_image')} attachmentData={backAttachment} setAttachmentData={setBackAttachment} />

        <button onClick={sendEvidenceImages} disabled={!frontAttachment || !backAttachment || sendingEvidence} className="send-images-button">
          <span>{t('sms.custom_send')}</span>
          <ArrowIcon />
        </button>
      </Row>
      <ShowErrorModal openShowErrorModal={error} setOpenShowErrorModal={setError} errorMessage={error} isBatchMultisignature={isBatchMultisignature} />
      {
        sendingEvidence &&
        <div className='loading-pages-container'>
          <Loader />
        </div>
      }
      
    </>
  );
};
