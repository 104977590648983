import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EmailSignatureService from "../../../services/EmailSignatureService";
import { BatchContext } from "../../../contexts/BatchContext";
import { OTP } from "../OTP/OTP";
import { AdditionalSignaturesContext } from "../../../contexts/AdditionalSignaturesContext";
import { AdditionalSignaturesFinalFlow } from "../AdditionalSignaturesFinalFlow/AdditionalSignaturesFinalFlow";

export const OTPDraw = ({ documents, emailId, setLoadingMorePages, otpCode, attachedImages, setOTPDraw }) => {
    const navigate = useNavigate();
    const { batchOTP, batchAttachedImages } = useContext(BatchContext);
    const [otpFlow, setOTPFlow] = useState(false);
    const [completeAdditionalSignaturesFlow, setCompleteAdditionalSignaturesFlow] = useState(false);
    const { isSignatureBoxesFlow } = useContext(AdditionalSignaturesContext);

    const documentData = Array.isArray(documents) ? documents[0] : documents;

    const email = documentData.SignerDTO.eMail;

    useEffect(() => {
        let link = "/sign";
        if (batchOTP || otpCode) {
            const { SkipSigDraw } = documentData.SignerDTO;

            // OTP Flow
            if (SkipSigDraw) return setOTPFlow(true);

            const shouldAttachDocumentImages = documentData.SignerDTO.AttachIdDocImages && typeof attachedImages === 'undefined' && !batchAttachedImages;
            if (shouldAttachDocumentImages) {
                link = "/attach-images";
            } else {
                if (isSignatureBoxesFlow) return setCompleteAdditionalSignaturesFlow(true);
            }

            navigate(link, {
                state: {
                    documents: documentData,
                    emailId,
                    otpCode,
                    attachedImages: shouldAttachDocumentImages
                }
            });
            return;
        }

        const phoneNumber = documentData.SignerDTO.PhoneNumber

        if (phoneNumber) {
            const body = {
                "PhoneNumber": phoneNumber
            };
            EmailSignatureService.sendOTPSms(emailId, body)
                .then((response) => {
                    const data = response.data;
                    let link = data.Channel.includes('SMS') ? "/sms" : "/call";
                    navigate(link, {
                        state: {
                            documents,
                            phoneNumber: phoneNumber,
                            emailId
                        }
                    });
                })
                .catch(err => {
                    setLoadingMorePages(false);
                    console.log(err);
                });
            return;
        }

        navigate("/customsms", {
            state: {
                documents,
                emailId
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>
        {otpFlow && <OTP signerGuid={documents.SignerDTO.SignerGUI} otpCode={batchOTP || otpCode} emailId={emailId} docGuid={documents.DocGUI} email={email} setOTPDraw={setOTPDraw} setLoadingMorePages={setLoadingMorePages} />}
        {completeAdditionalSignaturesFlow && <AdditionalSignaturesFinalFlow otpCode={batchOTP || otpCode} emailId={emailId} />}
    </>;
}