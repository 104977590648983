import { useContext, useEffect, useState } from "react"
import { getEvidenceData } from "../../../util/getEvidenceData";
import EmailSignatureService from "../../../services/EmailSignatureService";
import { useNavigate } from "react-router-dom";
import { BatchContext } from "../../../contexts/BatchContext";
import PendingDocumentService from "../../../services/PendingDocumentService";
import { FIRST_LOAD } from "../../../constants/GeneralConstants";
import { ConfirmationModalSignature } from "../../Modals/ConfirmationModalSignature/ConfirmationModalSignature";
import { AdditionalSignaturesContext } from "../../../contexts/AdditionalSignaturesContext";

export const AdditionalSignaturesFinalFlow = ({ otpCode = '', emailId, setOTPDraw, setLoadingMorePages }) => {
    const { remainingBatchDocuments, removeDocumentsFromBatch } = useContext(BatchContext);
    const [showConfirmationModalSignature, setShowConfirmationModalSignature] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const { documentData, signatureData } = useContext(AdditionalSignaturesContext);

    const { DocGUI: docGuid, SignerDTO: signerDTO } = documentData;
    const { SignerGUI: signerGuid, eMail: email } = signerDTO;

    useEffect(() => {
        setShowConfirmationModalSignature(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sendSignature = async () => {
        setLoading(true);
        const evidenceData = await getEvidenceData();

        let signatureBody;

        if (signatureData) {
            signatureBody = signatureData;
        } else {
            // get localstorage data for SSI, AOCValid and Criipto flows
            let additionalSignaturesData = localStorage.getItem("additionalSignaturesSignatureData");

            if (additionalSignaturesData) {
                additionalSignaturesData = JSON.parse(additionalSignaturesData);
                signatureBody = additionalSignaturesData;
            }
        }

        const { SignatureImage, SignatureRawData } = signatureBody;

        const body = {
            SignatureImage,
            SignatureRawData,
            OTP: otpCode,
            ...evidenceData,
        };

        EmailSignatureService.sendSign(signerGuid, body)
            .then((res) => {
                setLoading(false);
                if (res) {
                    // Clear local storage after signing doc
                    localStorage.removeItem("additionalSignaturesSignatureData");
                    setShowConfirmationModalSignature(false);
                    sessionStorage.setItem("SIGNED_DOC", true);
                    localStorage.setItem(FIRST_LOAD, "true")

                    removeDocumentsFromBatch([docGuid]);

                    if (remainingBatchDocuments > 1) {
                        navigate('/batch-success', {
                            state: {
                                emailId,
                            }
                        })
                        return;
                    }
                    PendingDocumentService.getPendingDocumentsList(emailId)
                        .then(res => {
                            const pendingDocuments = res.data.data;
                            setLoading(false);
                            navigate("/signature-success", {
                                state: {
                                    pendingDocuments,
                                    emailId,
                                    email
                                }
                            });
                        })
                        .catch(err => {
                            setLoading(false);
                            navigate("/signature-success", {
                                state: {
                                    pendingDocuments: [],
                                    emailId,
                                    email
                                }
                            });
                        });
                    return;
                }
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                console.log(err);
            });
        return;
    }
    return (
        <ConfirmationModalSignature showConfirmationModalSignature={showConfirmationModalSignature} setShowConfirmationModalSignature={setShowConfirmationModalSignature} handleSignature={sendSignature} sendingSignPetition={loading} setOTPDraw={setOTPDraw} setLoadingMorePages={setLoadingMorePages}/>
    );
}