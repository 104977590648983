import { createContext, useState } from "react";

export const AdditionalSignaturesContext = createContext(null);

export const AdditionalSignaturesContextProvider = ({ children }) => {
    const [documentData, setDocumentData] = useState(null);
    const [signatureData, setSignatureData] = useState(null);

    const signerDTO = documentData?.SignerDTO;
    const signatureBoxes = signerDTO?.SignatureBoxes;

    const isSignatureBoxesFlow = !!signatureBoxes;

    const isSignatureRecorded = !!signatureData;

    const [completedBoxes, setCompletedBoxes] = useState([]);

    const addCompletedBox = (boxData) => {
        const newCompletedBoxesData = [
            ...completedBoxes,
            boxData,
        ];

        setCompletedBoxes(newCompletedBoxesData);
    }

    const checkIfBoxIsCompleted = (boxData) => {
        return completedBoxes.find((box) => {
            return (box.PosX === boxData.PosX && 
                    box.Page === boxData.Page && 
                    box.PosY === boxData.PosY && 
                    box.SizeX === boxData.SizeX && 
                    box.SizeY === boxData.SizeY)
        });
    }

    const orderBoxesByPage = () => {
        signatureBoxes?.sort((previous, next) => {
            if (previous.Page === next.Page) {
                return previous.PosY - next.PosY;
            }
            return previous.Page - next.Page
        });
    }

    const getNextIncompletedBox = () => {
        let nextIncompleted = null;

        if (!isSignatureBoxesFlow) return null;

        for (const box of signatureBoxes) {
            if (!checkIfBoxIsCompleted(box)) {
                nextIncompleted = box;
                break;
            }
        }

        return nextIncompleted;
    }
    
    orderBoxesByPage();

    return (
        <AdditionalSignaturesContext.Provider value={{
            signatureBoxes,
            setDocumentData,
            signatureData,
            setSignatureData,
            isSignatureBoxesFlow,
            isSignatureRecorded,
            completedBoxes,
            addCompletedBox,
            checkIfBoxIsCompleted,
            signerDTO,
            getNextIncompletedBox,
            documentData,
        }}>
            {children}
        </AdditionalSignaturesContext.Provider>
    )
}