import React, { useEffect, useState } from 'react';

export const AutofirmaIcon = () => {

  const [color, setColor] = useState('');
  const DOMstyle = document.documentElement.style;

  // On Init
  useEffect(() => {
    const color = DOMstyle.getPropertyValue("--custom-primary-background-color");
    setColor(color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <svg className="card-img-top img-step" width="160" height="100" viewBox="0 0 157 153" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.3">
          <path d="M3.80087 103.111H1.21304C0.891323 103.111 0.582794 102.983 0.355304 102.757C0.127815 102.53 0 102.222 0 101.902C0 101.581 0.127815 101.274 0.355304 101.047C0.582794 100.82 0.891323 100.693 1.21304 100.693H3.80087C4.12258 100.693 4.43111 100.82 4.6586 101.047C4.88609 101.274 5.01391 101.581 5.01391 101.902C5.01391 102.222 4.88609 102.53 4.6586 102.757C4.43111 102.983 4.12258 103.111 3.80087 103.111Z" fill={color} />
          <path d="M13.8284 103.111H10.9171C10.5954 103.111 10.2869 102.983 10.0594 102.757C9.83192 102.53 9.7041 102.222 9.7041 101.902C9.7041 101.581 9.83192 101.274 10.0594 101.047C10.2869 100.82 10.5954 100.693 10.9171 100.693H13.8284C14.1502 100.693 14.4587 100.82 14.6862 101.047C14.9137 101.274 15.0415 101.581 15.0415 101.902C15.0415 102.222 14.9137 102.53 14.6862 102.757C14.4587 102.983 14.1502 103.111 13.8284 103.111Z" fill={color} />
          <path d="M7.4396 109.477C7.11789 109.477 6.80936 109.35 6.58187 109.123C6.35438 108.896 6.22656 108.589 6.22656 108.268V105.689C6.22656 105.369 6.35438 105.061 6.58187 104.835C6.80936 104.608 7.11789 104.48 7.4396 104.48C7.76132 104.48 8.06985 104.608 8.29734 104.835C8.52483 105.061 8.65265 105.369 8.65265 105.689V108.268C8.65265 108.589 8.52483 108.896 8.29734 109.123C8.06985 109.35 7.76132 109.477 7.4396 109.477Z" fill={color} />
          <path d="M7.4396 99.8059C7.11789 99.8059 6.80936 99.6786 6.58187 99.4519C6.35438 99.2252 6.22656 98.9177 6.22656 98.5971V95.6957C6.22656 95.3751 6.35438 95.0676 6.58187 94.8409C6.80936 94.6142 7.11789 94.4868 7.4396 94.4868C7.76132 94.4868 8.06985 94.6142 8.29734 94.8409C8.52483 95.0676 8.65265 95.3751 8.65265 95.6957V98.5971C8.65265 98.9177 8.52483 99.2252 8.29734 99.4519C8.06985 99.6786 7.76132 99.8059 7.4396 99.8059Z" fill={color} />
          <path d="M101.653 131.479H99.0656C98.7439 131.479 98.4353 131.352 98.2078 131.125C97.9804 130.899 97.8525 130.591 97.8525 130.27C97.8525 129.95 97.9804 129.642 98.2078 129.416C98.4353 129.189 98.7439 129.062 99.0656 129.062H101.653C101.975 129.062 102.284 129.189 102.511 129.416C102.739 129.642 102.866 129.95 102.866 130.27C102.866 130.591 102.739 130.899 102.511 131.125C102.284 131.352 101.975 131.479 101.653 131.479Z" fill={color} />
          <path d="M111.681 131.479H108.77C108.448 131.479 108.139 131.352 107.912 131.125C107.684 130.899 107.557 130.591 107.557 130.27C107.557 129.95 107.684 129.642 107.912 129.416C108.139 129.189 108.448 129.062 108.77 129.062H111.681C112.003 129.062 112.311 129.189 112.539 129.416C112.766 129.642 112.894 129.95 112.894 130.27C112.894 130.591 112.766 130.899 112.539 131.125C112.311 131.352 112.003 131.479 111.681 131.479Z" fill={color} />
          <path d="M105.292 137.846C104.97 137.846 104.662 137.719 104.434 137.492C104.207 137.265 104.079 136.958 104.079 136.637V134.058C104.079 133.737 104.207 133.43 104.434 133.203C104.662 132.976 104.97 132.849 105.292 132.849C105.614 132.849 105.922 132.976 106.15 133.203C106.377 133.43 106.505 133.737 106.505 134.058V136.637C106.505 136.958 106.377 137.265 106.15 137.492C105.922 137.719 105.614 137.846 105.292 137.846Z" fill={color} />
          <path d="M105.292 128.175C104.97 128.175 104.662 128.047 104.434 127.821C104.207 127.594 104.079 127.286 104.079 126.966V124.064C104.079 123.744 104.207 123.436 104.434 123.21C104.662 122.983 104.97 122.855 105.292 122.855C105.614 122.855 105.922 122.983 106.15 123.21C106.377 123.436 106.505 123.744 106.505 124.064V126.966C106.505 127.286 106.377 127.594 106.15 127.821C105.922 128.047 105.614 128.175 105.292 128.175Z" fill={color} />
          <path d="M144.028 21.5506H141.441C141.119 21.5506 140.81 21.4232 140.583 21.1965C140.355 20.9698 140.228 20.6623 140.228 20.3417C140.228 20.0211 140.355 19.7136 140.583 19.4869C140.81 19.2602 141.119 19.1328 141.441 19.1328H144.028C144.35 19.1328 144.659 19.2602 144.886 19.4869C145.114 19.7136 145.241 20.0211 145.241 20.3417C145.241 20.6623 145.114 20.9698 144.886 21.1965C144.659 21.4232 144.35 21.5506 144.028 21.5506Z" fill={color} />
          <path d="M154.056 21.5506H151.145C150.823 21.5506 150.514 21.4232 150.287 21.1965C150.059 20.9698 149.932 20.6623 149.932 20.3417C149.932 20.0211 150.059 19.7136 150.287 19.4869C150.514 19.2602 150.823 19.1328 151.145 19.1328H154.056C154.378 19.1328 154.686 19.2602 154.914 19.4869C155.141 19.7136 155.269 20.0211 155.269 20.3417C155.269 20.6623 155.141 20.9698 154.914 21.1965C154.686 21.4232 154.378 21.5506 154.056 21.5506Z" fill={color} />
          <path d="M147.668 27.9172C147.346 27.9172 147.038 27.7898 146.81 27.5631C146.583 27.3364 146.455 27.0289 146.455 26.7083V24.1293C146.455 23.8087 146.583 23.5012 146.81 23.2745C147.038 23.0478 147.346 22.9204 147.668 22.9204C147.99 22.9204 148.298 23.0478 148.526 23.2745C148.753 23.5012 148.881 23.8087 148.881 24.1293V26.7083C148.881 27.0289 148.753 27.3364 148.526 27.5631C148.298 27.7898 147.99 27.9172 147.668 27.9172Z" fill={color} />
          <path d="M147.668 18.2464C147.346 18.2464 147.038 18.119 146.81 17.8923C146.583 17.6656 146.455 17.3581 146.455 17.0375V14.1361C146.455 13.8155 146.583 13.508 146.81 13.2813C147.038 13.0546 147.346 12.9272 147.668 12.9272C147.99 12.9272 148.298 13.0546 148.526 13.2813C148.753 13.508 148.881 13.8155 148.881 14.1361V17.0375C148.881 17.3581 148.753 17.6656 148.526 17.8923C148.298 18.119 147.99 18.2464 147.668 18.2464Z" fill={color} />
          <path d="M21.7623 130.996C20.72 130.994 19.7209 130.581 18.984 129.846C18.2472 129.112 17.8329 128.116 17.832 127.077C17.8329 126.038 18.2472 125.043 18.984 124.308C19.7209 123.573 20.72 123.16 21.7623 123.159C22.8048 123.16 23.8044 123.573 24.5416 124.307C25.2788 125.042 25.6933 126.038 25.6942 127.077C25.6929 128.116 25.2782 129.112 24.5411 129.846C23.804 130.581 22.8047 130.994 21.7623 130.996ZM21.7623 125.576C21.3632 125.577 20.9806 125.735 20.6986 126.017C20.4166 126.298 20.2581 126.679 20.2581 127.077C20.2585 127.475 20.4171 127.856 20.6991 128.137C20.981 128.418 21.3633 128.577 21.7623 128.578C22.1615 128.577 22.5443 128.419 22.8266 128.138C23.1088 127.856 23.2677 127.475 23.2681 127.077C23.2683 126.88 23.2295 126.685 23.1539 126.502C23.0784 126.32 22.9675 126.154 22.8276 126.015C22.6878 125.875 22.5217 125.765 22.3389 125.689C22.1561 125.614 21.9602 125.575 21.7623 125.575V125.576Z" fill={color} />
          <path d="M151.145 55.1788C150.102 55.1775 149.103 54.7643 148.366 54.0297C147.629 53.2951 147.214 52.2992 147.213 51.2604C147.214 50.2212 147.628 49.2249 148.365 48.49C149.102 47.7551 150.102 47.3416 151.145 47.3403C152.187 47.3416 153.187 47.7549 153.924 48.4894C154.661 49.224 155.075 50.2199 155.077 51.2588C155.076 52.2979 154.661 53.2942 153.924 54.0291C153.187 54.764 152.187 55.1775 151.145 55.1788ZM151.145 49.7597C150.746 49.7606 150.363 49.919 150.081 50.2002C149.799 50.4814 149.64 50.8626 149.639 51.2604C149.64 51.6581 149.799 52.0393 150.081 52.3205C150.363 52.6018 150.746 52.7602 151.145 52.761C151.544 52.7602 151.926 52.6018 152.209 52.3205C152.491 52.0393 152.65 51.6581 152.651 51.2604C152.65 50.8623 152.491 50.4807 152.209 50.1992C151.927 49.9176 151.544 49.759 151.145 49.7581V49.7597Z" fill={color} />
          <path d="M124.702 16.7955C124.446 16.7956 124.196 16.7143 123.989 16.5634C121.655 14.871 119.225 13.3146 116.711 11.9019C116.431 11.7448 116.224 11.4831 116.138 11.1745C116.051 10.8659 116.091 10.5356 116.248 10.2562C116.406 9.97684 116.669 9.77136 116.979 9.68493C117.289 9.59849 117.621 9.63816 117.901 9.79523C120.496 11.2542 123.004 12.8612 125.412 14.6082C125.541 14.7017 125.65 14.8195 125.733 14.955C125.817 15.0904 125.872 15.2409 125.897 15.3977C125.922 15.5546 125.915 15.7148 125.878 15.8692C125.841 16.0236 125.773 16.1691 125.679 16.2975C125.567 16.4513 125.42 16.5767 125.251 16.6632C125.081 16.7498 124.893 16.7951 124.702 16.7955Z" fill={color} />
          <path d="M57.8739 5.79949C57.6152 5.79946 57.3633 5.717 57.155 5.56418C56.9466 5.41136 56.7928 5.19618 56.7159 4.95004C56.6686 4.79838 56.6516 4.63891 56.6662 4.48075C56.6807 4.32259 56.7265 4.16884 56.8007 4.02828C56.8749 3.88772 56.9761 3.76312 57.0987 3.66158C57.2212 3.56004 57.3626 3.48355 57.5148 3.4365C64.8992 1.15147 72.5879 -0.00712638 80.32 3.29775e-05C86.3799 -0.00197329 92.4188 0.707841 98.3118 2.11479C98.6231 2.18978 98.8919 2.38436 99.0598 2.65611C99.2277 2.92787 99.2809 3.25475 99.2079 3.56546C99.1326 3.87564 98.9374 4.14358 98.6647 4.31086C98.392 4.47814 98.064 4.53117 97.7522 4.45841C92.0446 3.09526 86.1957 2.40763 80.3265 2.40975C72.8396 2.40267 65.3945 3.52432 58.2443 5.73663C58.1248 5.77662 57.9999 5.79783 57.8739 5.79949Z" fill={color} />
          <path d="M28.516 22.9318C28.3507 22.932 28.187 22.8986 28.0352 22.8334C27.8833 22.7683 27.7464 22.6728 27.6329 22.553C27.4121 22.32 27.2931 22.0091 27.3022 21.6887C27.3113 21.3683 27.4477 21.0646 27.6814 20.8444C28.8832 19.7161 30.135 18.6104 31.4014 17.5627C31.5241 17.4613 31.6657 17.385 31.818 17.3382C31.9703 17.2914 32.1303 17.2751 32.289 17.29C32.4476 17.305 32.6018 17.3511 32.7426 17.4255C32.8834 17.4999 33.008 17.6013 33.1094 17.7239C33.2109 17.8462 33.2873 17.9873 33.3341 18.139C33.381 18.2908 33.3973 18.4502 33.3823 18.6083C33.3673 18.7664 33.3212 18.9199 33.2466 19.0602C33.172 19.2005 33.0704 19.3248 32.9477 19.426C31.7185 20.4414 30.5054 21.5117 29.3425 22.6062C29.1183 22.815 28.8229 22.9313 28.516 22.9318Z" fill={color} />
          <path d="M5.4485 87.1952C5.15341 87.1948 4.86863 87.0872 4.64742 86.8926C4.42622 86.6979 4.28377 86.4296 4.24679 86.1378C3.8381 82.9146 3.63336 79.6689 3.63379 76.42C3.63339 73.1868 3.8365 69.9567 4.24193 66.7488C5.47912 56.9056 8.64735 47.4011 13.5662 38.7767C13.6445 38.6384 13.7495 38.5169 13.875 38.4192C14.0005 38.3214 14.1442 38.2492 14.2977 38.2069C14.4512 38.1645 14.6117 38.1527 14.7698 38.1722C14.9279 38.1917 15.0806 38.2421 15.2192 38.3205C15.3579 38.3986 15.4798 38.5032 15.5779 38.6283C15.676 38.7534 15.7484 38.8965 15.7909 39.0496C15.8334 39.2026 15.8453 39.3624 15.8257 39.52C15.8061 39.6776 15.7555 39.8298 15.6769 39.9678C10.9151 48.317 7.84746 57.518 6.64863 67.047C6.25624 70.1539 6.05959 73.2822 6.05987 76.4135C6.05942 79.5606 6.25766 82.7045 6.65344 85.8268C6.67385 85.9843 6.66288 86.1442 6.62114 86.2975C6.5794 86.4508 6.50771 86.5944 6.4102 86.72C6.31269 86.8456 6.19127 86.9509 6.05288 87.0296C5.9145 87.1084 5.76187 87.1592 5.60376 87.1791C5.55239 87.1877 5.50054 87.1931 5.4485 87.1952Z" fill={color} />
          <path d="M42.6178 142.803C42.4054 142.803 42.1967 142.747 42.0128 142.641C39.0586 140.939 36.2209 139.044 33.5183 136.968C33.3922 136.871 33.2864 136.75 33.207 136.612C33.1277 136.475 33.0763 136.323 33.0559 136.165C33.0355 136.008 33.0464 135.848 33.088 135.695C33.1296 135.541 33.2011 135.398 33.2984 135.272C33.3956 135.146 33.5168 135.041 33.655 134.962C33.7931 134.883 33.9456 134.832 34.1036 134.811C34.2616 134.791 34.422 134.802 34.5758 134.843C34.7296 134.885 34.8737 134.956 34.9998 135.053C37.6171 137.063 40.3651 138.899 43.2259 140.548C43.3638 140.627 43.4847 140.733 43.5816 140.858C43.6786 140.984 43.7497 141.128 43.7909 141.281C43.832 141.435 43.8425 141.595 43.8216 141.752C43.8007 141.909 43.7488 142.061 43.6691 142.198C43.5627 142.382 43.4094 142.535 43.2248 142.641C43.0403 142.747 42.8309 142.803 42.6178 142.803Z" fill={color} />
          <path d="M80.3159 152.841C73.5091 152.846 66.7318 151.95 60.1617 150.177C60.0079 150.135 59.8638 150.064 59.7377 149.967C59.6116 149.87 59.506 149.749 59.4269 149.611C59.3477 149.474 59.2965 149.322 59.2764 149.164C59.2562 149.007 59.2674 148.847 59.3093 148.694C59.3509 148.54 59.4223 148.397 59.5197 148.271C59.617 148.146 59.7383 148.04 59.8765 147.961C60.0147 147.883 60.1671 147.832 60.3251 147.811C60.4831 147.791 60.6436 147.803 60.7972 147.844C67.1603 149.561 73.7239 150.428 80.3159 150.423C85.6563 150.425 90.9814 149.856 96.2003 148.728C96.3559 148.693 96.5168 148.69 96.6736 148.718C96.8304 148.746 96.9801 148.805 97.1142 148.891C97.2482 148.976 97.364 149.088 97.4547 149.218C97.5455 149.349 97.6094 149.496 97.643 149.651C97.7113 149.964 97.652 150.292 97.4783 150.561C97.3045 150.831 97.0304 151.021 96.7162 151.089C91.3279 152.255 85.8298 152.842 80.3159 152.841Z" fill={color} />
          <path d="M117.311 143.201C117.096 143.201 116.884 143.144 116.698 143.036C116.512 142.928 116.359 142.772 116.253 142.585C116.096 142.306 116.056 141.975 116.143 141.667C116.229 141.358 116.435 141.096 116.716 140.939C118.529 139.921 120.323 138.812 122.043 137.643C122.175 137.554 122.324 137.492 122.48 137.459C122.636 137.427 122.797 137.426 122.953 137.456C123.11 137.486 123.259 137.547 123.392 137.634C123.525 137.721 123.639 137.834 123.729 137.965C123.818 138.097 123.881 138.245 123.914 138.4C123.946 138.556 123.947 138.716 123.917 138.872C123.887 139.028 123.826 139.177 123.738 139.309C123.651 139.442 123.537 139.556 123.405 139.645C121.626 140.852 119.776 141.997 117.906 143.049C117.724 143.15 117.519 143.202 117.311 143.201Z" fill={color} />
          <path d="M136.31 128.057C136.011 128.057 135.723 127.946 135.502 127.746C135.263 127.531 135.12 127.23 135.104 126.91C135.088 126.589 135.2 126.276 135.416 126.038C143.7 116.907 149.569 105.863 152.494 93.9037C152.57 93.5919 152.767 93.3229 153.042 93.1558C153.316 92.9887 153.646 92.937 153.959 93.0123C154.114 93.0497 154.26 93.1172 154.389 93.2109C154.517 93.3045 154.626 93.4225 154.709 93.5581C154.792 93.6937 154.847 93.8443 154.872 94.0012C154.896 94.1581 154.89 94.3183 154.852 94.4726C151.831 106.823 145.769 118.229 137.214 127.659C137.1 127.785 136.961 127.886 136.805 127.955C136.649 128.023 136.481 128.058 136.31 128.057Z" fill={color} />
          <path d="M155.787 77.6286C155.628 77.6288 155.47 77.5977 155.323 77.537C155.175 77.4764 155.042 77.3873 154.929 77.275C154.816 77.1627 154.727 77.0294 154.666 76.8826C154.605 76.7358 154.574 76.5786 154.574 76.4197C154.574 74.1938 154.474 71.9468 154.275 69.7434C154.113 67.9365 153.882 66.1183 153.589 64.3372C153.537 64.0212 153.613 63.6975 153.801 63.4373C153.988 63.1771 154.272 63.0016 154.589 62.9494C154.746 62.9234 154.907 62.9285 155.062 62.9645C155.217 63.0005 155.364 63.0667 155.493 63.1592C155.623 63.2517 155.733 63.3688 155.817 63.5038C155.901 63.6387 155.957 63.7888 155.983 63.9456C156.285 65.7847 156.525 67.6625 156.691 69.5274C156.897 71.8034 157 74.1228 157 76.4197C157 76.7404 156.872 77.0478 156.645 77.2745C156.417 77.5013 156.109 77.6286 155.787 77.6286Z" fill={color} />
          <path d="M74.9068 24.7953C73.8711 24.7941 72.8781 24.3831 72.1457 23.6526C71.4133 22.922 71.0013 21.9316 71 20.8985C71.0009 19.8651 71.4127 18.8742 72.1451 18.1433C72.8776 17.4125 73.8708 17.0013 74.9068 17C75.9426 17.0013 76.9356 17.4122 77.668 18.1428C78.4004 18.8733 78.8124 19.8637 78.8137 20.8969C78.8128 21.9303 78.401 22.9211 77.6686 23.652C76.9361 24.3829 75.9429 24.7941 74.9068 24.7953ZM74.9068 19.4061C74.5103 19.4069 74.1302 19.5644 73.8498 19.8441C73.5694 20.1238 73.4115 20.5029 73.4106 20.8985C73.4115 21.294 73.5694 21.6731 73.8498 21.9528C74.1302 22.2325 74.5103 22.39 74.9068 22.3908C75.3034 22.39 75.6834 22.2325 75.9638 21.9528C76.2443 21.6731 76.4022 21.294 76.403 20.8985C76.4026 20.5026 76.2449 20.1231 75.9645 19.8431C75.684 19.5631 75.3037 19.4053 74.9068 19.4045V19.4061Z" fill={color} />
        </g>
        <path fillRule="evenodd" clipRule="evenodd" d="M53.5003 72C52.6719 72 52.0003 72.6716 52.0003 73.5C52.0003 74.3284 52.6719 75 53.5003 75H80.8574C81.6859 75 82.3574 74.3284 82.3574 73.5C82.3574 72.6716 81.6859 72 80.8574 72H53.5003ZM87.5003 72C86.6719 72 86.0003 72.6716 86.0003 73.5C86.0003 74.3284 86.6719 75 87.5003 75H101.5C102.329 75 103 74.3284 103 73.5C103 72.6716 102.329 72 101.5 72H87.5003ZM77.4244 115.411C86.8552 115.411 94.5003 107.766 94.5003 98.3348C94.5003 88.9041 86.8552 81.2589 77.4244 81.2589C67.9937 81.2589 60.3485 88.9041 60.3485 98.3348C60.3485 107.766 67.9937 115.411 77.4244 115.411ZM77.5009 113C85.509 113 92.0009 106.508 92.0009 98.5C92.0009 90.4919 85.509 84 77.5009 84C69.4927 84 63.0009 90.4919 63.0009 98.5C63.0009 106.508 69.4927 113 77.5009 113ZM83.4984 93.7039C84.078 93.112 85.0623 93.0638 85.6969 93.5963C86.3315 94.1288 86.3761 95.0403 85.7966 95.6323L77.6944 103.907C77.265 104.346 76.6135 104.486 76.0467 104.313C75.7144 104.259 75.3976 104.106 75.1529 103.857L70.5902 99.1964C70.0106 98.6045 70.0552 97.6929 70.6898 97.1604C71.3244 96.6279 72.3087 96.6761 72.8883 97.2681L76.4486 100.904L83.4984 93.7039Z" fill={color} />
        <path fillRule="evenodd" clipRule="evenodd" d="M42 40.5C40.067 40.5 38.5 42.067 38.5 44V96C38.5 97.933 40.067 99.5 42 99.5H56.2651C57.0935 99.5 57.7651 100.172 57.7651 101C57.7651 101.828 57.0935 102.5 56.2651 102.5H42C38.4101 102.5 35.5 99.5899 35.5 96V44C35.5 40.4101 38.4101 37.5 42 37.5H114C117.59 37.5 120.5 40.4101 120.5 44V96C120.5 99.5899 117.59 102.5 114 102.5H100.229C99.4005 102.5 98.7289 101.828 98.7289 101C98.7289 100.172 99.4005 99.5 100.229 99.5H114C115.933 99.5 117.5 97.933 117.5 96V44C117.5 42.067 115.933 40.5 114 40.5H42Z" fill={color} />
        <path fillRule="evenodd" clipRule="evenodd" d="M46 47.5C45.7239 47.5 45.5 47.7239 45.5 48V91.5C45.5 91.7761 45.7239 92 46 92H55.5C56.3284 92 57 92.6716 57 93.5C57 94.3284 56.3284 95 55.5 95H46C44.067 95 42.5 93.433 42.5 91.5V48C42.5 46.067 44.067 44.5 46 44.5H110C111.933 44.5 113.5 46.067 113.5 48V91.5C113.5 93.433 111.933 95 110 95H100.5C99.6716 95 99 94.3284 99 93.5C99 92.6716 99.6716 92 100.5 92H110C110.276 92 110.5 91.7761 110.5 91.5V48C110.5 47.7239 110.276 47.5 110 47.5H46Z" fill={color} />
        <rect x="53.5" y="57.5" width="48" height="8" rx="1.5" stroke={color} strokeWidth="3" />
        <path d="M69 111.5V128.793C69 129.238 69.5386 129.461 69.8536 129.146L77.5 121.5L85.1464 129.146C85.4614 129.461 86 129.238 86 128.793V111.5" stroke={color} strokeWidth="2.5" />
      </svg>
    </>
  );
}