import Cookies from 'js-cookie';

const USERNAME = process.env.REACT_APP_VID_CLOUD_USERNAME;
const PASSWORD = process.env.REACT_APP_VID_CLOUD_PASSWORD;

export const generateBasicAuthHeaders = () => {
    return {
        headers: {
            'Authorization': 'Basic ' + btoa(`${USERNAME}:${PASSWORD}`),
            'Content-Type': 'application/json;charset=utf-8',
            'Accept': 'application/json;charset=utf-8',
        },
    };
};

export const generateOAuthRequestHeaders = () => {
    const token = Cookies.get('token');
    return {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json;charset=utf-8',
        'Accept': 'application/json;charset=utf-8',
    }
  }
}

export const generateOAuthRequestWithSignalHeaders = (signal) => {
  const token = Cookies.get('token');
  return {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json;charset=utf-8',
      'Accept': 'application/json;charset=utf-8',
    },
    signal: signal
  }
}