import { Modal } from "antd"
import { Col, Row } from "react-bootstrap"
import { CheckIcon } from "../../SvgComponents/CheckIcon/CheckIcon"
import { useTranslation } from "react-i18next";
import cancel from "./../../../assets/icons/cancel.svg";

export const ReplicateAddiionalSignatureModal = ({ showConfirmationModalSignature, handleCloseConfirmationModal, handleReplicateSignature }) => {
    const { t } = useTranslation();

    return (
        <Modal
            title={t("additional_signatures.replicate_signature")}
            footer={null}
            open={showConfirmationModalSignature}
            closable={true}
            onCancel={handleCloseConfirmationModal}
        >
            <div className="modal-container">
                <p className="nu-input-label">{t("additional_signatures.consent_to_replicate_in_position")}</p>
                <Row className="action-buttons mobile-button">
                    <Col md={6} xs={6}>
                        <button type="button" className="btn btn-secondary btn-cancel" onClick={handleCloseConfirmationModal}>
                            <span className="cancel-text">{t("general.cancel")}</span>
                            <img className="icon-image" src={cancel} alt="Check" />
                        </button>
                    </Col>
                    <Col md={6} xs={6}>
                        <button type="button" className="btn btn-primary btn-active" onClick={handleReplicateSignature}>
                            <span className="cancel-text">{t('general.confirm')}</span>
                            <CheckIcon />
                        </button>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
}