import React, { useEffect, useState } from 'react';

export const SignatureReadyIcon = ({ positionStyling }) => {
  const [color, setColor] = useState('');
  const DOMstyle = document.documentElement.style;

    // On Init
    useEffect(() => {
        const color = DOMstyle.getPropertyValue("--custom-primary-background-color");
        setColor(color);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

  return (
    <svg style={positionStyling} width="51" height="17" viewBox="0 0 51 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.86231 9.00593C11.2483 9.94294 16.8988 14.7775 20.5021 11.1547C24.5191 7.11592 23.6029 1.49144 21.965 5.74979C17.6551 16.9546 23.7665 10.4996 28.5163 6.8963C32.4231 3.93252 22.1837 17.4499 25.4044 11.1547C29.0076 4.11198 32.9384 15.7406 37.3606 9.35306" stroke="black" stroke-width="0.5" stroke-linecap="round"/>
        <path d="M44.8844 4.71927L48.8965 0.70713L50.0409 1.85156L46.0288 5.8637L44.7919 5.95616L44.8844 4.71927Z" stroke={color}/>
        <path d="M49.2813 3.31812L47.4297 1.46658" stroke={color}/>
        <path d="M50 4.66279V15C50 15.5523 49.5523 16 49 16H2C1.44771 16 1 15.5523 1 15V2C1 1.44772 1.44772 1 2 1H46.15" stroke={color} stroke-linecap="round"/>
    </svg>
  );
}