import React, { useContext, useEffect, useState } from 'react';
import Helper from '../../../util/helper';
import { SignatureReadyIcon } from '../SignatureReadyIcon/SignatureReadyIcon';
import { AdditionalSignaturesContext } from '../../../contexts/AdditionalSignaturesContext';

export const SignatureIndicator = ({ boxData }) => {
  const [color, setColor] = useState('');
  const DOMstyle = document.documentElement.style;

  const { checkIfBoxIsCompleted } = useContext(AdditionalSignaturesContext);

  // On Init
  useEffect(() => {
    const color = DOMstyle.getPropertyValue("--custom-secondary-background-color");
    setColor(color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPositionStyles = () => {
    const { PosX, PosY } = boxData;
    return Helper.getSignatureIndicatorPositionStyles(PosX, PosY);
  }

  const styleObject = getPositionStyles();

  const substract35PxToLeftPosition = (leftProperty) => {
    const leftValue = leftProperty.split('px')[0];

    const newValue = parseInt(leftValue) - 35;
    
    return newValue;
  }

  const isReady = checkIfBoxIsCompleted(boxData);

  return (
      isReady ? <SignatureReadyIcon positionStyling={styleObject} /> : 
      <>
        <svg style={{
          ...styleObject,
          left: substract35PxToLeftPosition(styleObject?.left)
        }} width="29" height="16" viewBox="0 0 29 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0H20.9135L29 8L20.9135 16H0V0Z" fill={color}/>
        </svg>
        <svg style={styleObject} width="44" height="19" viewBox="0 0 44 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="43" height="18" rx="1.5" fill="#E5F9F1" stroke={color} stroke-dasharray="2 2"/>
        </svg>
      </>
  );
}