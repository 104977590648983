import { generateOAuthRequestHeaders, generateOAuthRequestWithSignalHeaders } from './generateHeaders';

import { axiosInstance } from './../services/RemoteService';

const PRIV_BASE_URI = process.env.REACT_APP_PRIV_BASE_URI;

export const privGetRequest = (url) => {
    const httpOptions = generateOAuthRequestHeaders();

    return axiosInstance.get(PRIV_BASE_URI + url, httpOptions);
};

export const privGetRequestWithSignal = (url, signal) => {
    const httpOptions = generateOAuthRequestWithSignalHeaders(signal);
    return axiosInstance.get(PRIV_BASE_URI + url, httpOptions);
};

export const privPostRequest = (url, body, token) => {  
    const httpOptions = generateOAuthRequestHeaders(token);

    return axiosInstance.post(PRIV_BASE_URI + url, body, httpOptions);
};

export const privPutRequest = (url, body, token) => {
    const httpOptions = generateOAuthRequestHeaders(token);

    return axiosInstance.put(PRIV_BASE_URI + url, body, httpOptions);
};

export const privDeleteRequest = (url, token) => {
    const httpOptions = generateOAuthRequestHeaders(token);

    return axiosInstance.delete(PRIV_BASE_URI + url, httpOptions);
};