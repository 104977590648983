import { useEffect, useState } from "react";
import SignatureService from "../../services/SignatureService";
import { QuestionsErrorModal } from "../Modals/QuestionsErrorModal/QuestionsErrorModal";
import { useTranslation } from 'react-i18next';

export const FormOnPreview = ({ setLoadingMorePages, areQuestionsAnswered, startCorrectSignMethod, signerGuid, formTransform, form, setFormOnPreview, formOnPreviewError, isAutofirma, setAutofirma}) => {
    const [openQuestionErrorModal, setOpenQuestionErrorModal] = useState(false);
    const { t } = useTranslation();
    const errorMessageModal = t('document_id.file_size_limit_exceeded');
    
    useEffect(() => {
        const canContinue = areQuestionsAnswered();
        if (!canContinue) {
            setOpenQuestionErrorModal(true);
            return;
        }

        const array = [].concat(...formTransform);
        let result = [].concat(...array.map(val => val.components.map(comp => {
        return { id: comp.id, response: ((comp.response === undefined || comp.response === null) ? comp.selectedchoice : comp.response) };
        })));
        result = result.filter(val => val.response !== undefined);
        setLoadingMorePages(true);
        SignatureService.signatureFormResponse(signerGuid, form, result, true)
        .then(() => {
            // Autofirma
            if (typeof isAutofirma !== 'undefined' && isAutofirma !== 'no') return setAutofirma(true);
            startCorrectSignMethod();
        })
        .catch(err => {
            setLoadingMorePages(false);
            const errorMessage = err.response.data;
            if (errorMessage.includes('bigger') || errorMessage.includes('greater')) {
                setFormOnPreview(false);
                formOnPreviewError(errorMessageModal);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <QuestionsErrorModal setOpenQuestionErrorModal={setOpenQuestionErrorModal} openQuestionErrorModal={openQuestionErrorModal} setFormOnPreview={setFormOnPreview} isAdvancedForm={true} />
    );
};