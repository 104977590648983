import { Col, Form, Row } from "react-bootstrap";
import continueIcon from "./../../../assets/icons/arrow-right.svg";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import EmailSignatureService from "../../../services/EmailSignatureService";
import { useContext, useState } from "react";
import { SmsSendingIcon } from "../../SvgComponents/SmsSendingIcon/SmsSendingIcon";
import { getEvidenceData } from "../../../util/getEvidenceData";
import './SwisscomModal.css';
import PendingDocumentService from "../../../services/PendingDocumentService";
import { BatchContext } from "../../../contexts/BatchContext";
import { useNavigate } from "react-router-dom";
import { useOrientation } from "../../../hooks/useOrientation";
import { CancelIcon } from "../../SvgComponents/CancelIcon/CancelIcon";
import { CheckIcon } from "../../SvgComponents/CheckIcon/CheckIcon";

export const SwisscomModal = ({
  openSwisscomModal,
  setOpenSwisscomModal,
  signerGuid,
  phoneNumber,
  email,
  emailId,
  docGuid,
  setParentLoading,
}) => {

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [requestID, setRequestID] = useState();
  const widthIcon = 150;
  const heightIcon = 147;

  const navigate = useNavigate();

  const { remainingBatchDocuments, removeDocumentsFromBatch, selectedBatchDocumentGuids } = useContext(BatchContext);

  const handleAuthorizeSwisscom = async () => {
    setLoading(true);

    const evidenceData = await getEvidenceData();

    const body = {
      ...evidenceData,
      PhoneNumber: phoneNumber,
    };

    EmailSignatureService.sendSwisscomSignRequest(signerGuid, body)
      .then((response) => {
        const { RequestID } = response?.data;

        setRequestID(RequestID);
        setLoading(false);
      })
      .catch((e) => {
        console.log({ e })
        setError(e?.response?.data);
        setLoading(false);
      })
  }

  const handleCancel = () => {
    setOpenSwisscomModal(false);

    setParentLoading(false);
  }

  const handleContinueSwisscom = async () => {
    setLoading(true);

    const evidenceData = await getEvidenceData();

    const body = {
      ...evidenceData,
      PhoneNumber: phoneNumber,
    };

    const isBatchMultisignature = selectedBatchDocumentGuids.length > 0;

    EmailSignatureService.completeSwisscomSignature(signerGuid, requestID, body)
      .then(() => {
        removeDocumentsFromBatch(isBatchMultisignature ? selectedBatchDocumentGuids : [docGuid]);
        const pendingBatchDocsLength = remainingBatchDocuments - (selectedBatchDocumentGuids.length ? selectedBatchDocumentGuids.length : 1);

        if (pendingBatchDocsLength > 0) {
          setLoading(false);
          navigate('/batch-success', {
            state: {
              emailId,
            }
          })
          return;
        }
        PendingDocumentService.getPendingDocumentsList(emailId)
          .then(res => {
            const pendingDocuments = res.data;
            setLoading(false);
            navigate("/signature-success", {
              state: {
                pendingDocuments,
                emailId,
                email
              }
            });
          })
          .catch(err => {
            setLoading(false);
            navigate("/signature-success", {
              state: {
                pendingDocuments: [],
                emailId,
                email
              }
            });
          });
      })
      .catch((e) => {
        console.log({ e })
        setError(e?.response?.data);
        setLoading(false);
      })
  }

  return (
    <>
      <Modal
        title=""
        style={{ top: 150 }}
        footer={null}
        open={openSwisscomModal}
        closable={true}
        onCancel={handleCancel}
        className="swisscom-modal"
      >
        <div className="modal-container">
          <SmsSendingIcon width={widthIcon} height={heightIcon} />
          <p style={{ marginTop: '1rem' }}><b>{t("general.authorize")}</b></p>
          <p className="nu-input-label">{t("sms.swisscom_auth_description")}</p>
          <Form className="phone-number-form">
            <Form.Group className="mb-3" controlId="">
              <Form.Control className="phone-number-input-disabled" type="text" value={phoneNumber} disabled />
            </Form.Group>
          </Form>
          <Row className="action-buttons mobile-button">
            <Col md={6} xs={6} lg={6}>
              <button type="button" className="btn btn-secondary btn-cancel" onClick={handleCancel}>
                <span className="cancel-text">{t("general.cancel")}</span>
                <CancelIcon />
              </button>
            </Col>
            <Col md={6} xs={6} lg={6}>
              <button type="button" className="btn btn-primary btn-active" onClick={handleAuthorizeSwisscom} disabled={loading}>
                <span className="cancel-text">{t('general.authorize')}</span>
                <CheckIcon disabled={loading} />
              </button>
            </Col>
            {
              requestID && <>
                <p className="nu-input-label swisscom-second-step">{t("sms.swisscom_app_description")}</p>
                <Col md={1} lg={1} xs={0} sm={0}></Col>
                <Col md={10} xs={12} lg={10} sm={12}>
                  <button type="button" className="btn btn-primary btn-active" onClick={handleContinueSwisscom} disabled={loading}>
                    <span className="cancel-text">{t("general.continue")}</span>
                    <CheckIcon disabled={loading} />
                  </button>
                </Col>
                <Col md={1} lg={1} xs={0} sm={0}></Col>
              </>
            }
          </Row>
        </div>
      </Modal>
      <Modal
        title="Error"
        style={{ top: 150 }}
        footer={null}
        open={error}
        closable={true}
        onCancel={() => setError(false)}
        className="swisscom-modal"
      >
        {error}
      </Modal>
    </>
  );
};
