import React, { useEffect, useState } from 'react';

export const AuthSSIIcon = () => {

  const [color, setColor] = useState('');
  const DOMstyle = document.documentElement.style;

  // On Init
  useEffect(() => {
    const color = DOMstyle.getPropertyValue("--custom-primary-background-color");
    setColor(color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <svg className="card-img-top img-step" width="156" height="152" viewBox="0 0 156 152" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.3">
          <path d="M3.77666 102.543H1.20532C0.885646 102.543 0.579082 102.416 0.353041 102.191C0.127001 101.966 0 101.66 0 101.341C0 101.022 0.127001 100.716 0.353041 100.491C0.579082 100.265 0.885646 100.139 1.20532 100.139H3.77666C4.09633 100.139 4.40289 100.265 4.62893 100.491C4.85497 100.716 4.98197 101.022 4.98197 101.341C4.98197 101.66 4.85497 101.966 4.62893 102.191C4.40289 102.416 4.09633 102.543 3.77666 102.543Z" fill={color} />
          <path d="M13.7407 102.543H10.8479C10.5282 102.543 10.2217 102.416 9.99562 102.191C9.76958 101.966 9.64258 101.66 9.64258 101.341C9.64258 101.022 9.76958 100.716 9.99562 100.491C10.2217 100.265 10.5282 100.139 10.8479 100.139H13.7407C14.0603 100.139 14.3669 100.265 14.5929 100.491C14.819 100.716 14.946 101.022 14.946 101.341C14.946 101.66 14.819 101.966 14.5929 102.191C14.3669 102.416 14.0603 102.543 13.7407 102.543Z" fill={color} />
          <path d="M7.39282 108.875C7.07315 108.875 6.76658 108.748 6.54054 108.523C6.3145 108.297 6.1875 107.992 6.1875 107.673V105.108C6.1875 104.789 6.3145 104.483 6.54054 104.258C6.76658 104.032 7.07315 103.906 7.39282 103.906C7.71249 103.906 8.01905 104.032 8.24509 104.258C8.47113 104.483 8.59813 104.789 8.59813 105.108V107.673C8.59813 107.992 8.47113 108.297 8.24509 108.523C8.01905 108.748 7.71249 108.875 7.39282 108.875Z" fill={color} />
          <path d="M7.39282 99.2571C7.07315 99.2571 6.76658 99.1305 6.54054 98.905C6.3145 98.6796 6.1875 98.3738 6.1875 98.0549V95.1695C6.1875 94.8507 6.3145 94.5449 6.54054 94.3194C6.76658 94.0939 7.07315 93.9673 7.39282 93.9673C7.71249 93.9673 8.01905 94.0939 8.24509 94.3194C8.47113 94.5449 8.59813 94.8507 8.59813 95.1695V98.0549C8.59813 98.3738 8.47113 98.6796 8.24509 98.905C8.01905 99.1305 7.71249 99.2571 7.39282 99.2571Z" fill={color} />
          <path d="M143.112 21.4318H140.54C140.221 21.4318 139.914 21.3052 139.688 21.0797C139.462 20.8542 139.335 20.5484 139.335 20.2296C139.335 19.9107 139.462 19.6049 139.688 19.3795C139.914 19.154 140.221 19.0273 140.54 19.0273H143.112C143.431 19.0273 143.738 19.154 143.964 19.3795C144.19 19.6049 144.317 19.9107 144.317 20.2296C144.317 20.5484 144.19 20.8542 143.964 21.0797C143.738 21.3052 143.431 21.4318 143.112 21.4318Z" fill={color} />
          <path d="M153.075 21.4318H150.182C149.862 21.4318 149.556 21.3052 149.33 21.0797C149.104 20.8542 148.977 20.5484 148.977 20.2296C148.977 19.9107 149.104 19.6049 149.33 19.3795C149.556 19.154 149.862 19.0273 150.182 19.0273H153.075C153.394 19.0273 153.701 19.154 153.927 19.3795C154.153 19.6049 154.28 19.9107 154.28 20.2296C154.28 20.5484 154.153 20.8542 153.927 21.0797C153.701 21.3052 153.394 21.4318 153.075 21.4318Z" fill={color} />
          <path d="M146.727 27.7637C146.407 27.7637 146.101 27.637 145.875 27.4116C145.648 27.1861 145.521 26.8803 145.521 26.5615V23.9967C145.521 23.6778 145.648 23.372 145.875 23.1466C146.101 22.9211 146.407 22.7944 146.727 22.7944C147.046 22.7944 147.353 22.9211 147.579 23.1466C147.805 23.372 147.932 23.6778 147.932 23.9967V26.5615C147.932 26.8803 147.805 27.1861 147.579 27.4116C147.353 27.637 147.046 27.7637 146.727 27.7637Z" fill={color} />
          <path d="M146.727 18.1458C146.407 18.1458 146.101 18.0192 145.875 17.7937C145.648 17.5682 145.521 17.2624 145.521 16.9436V14.0582C145.521 13.7393 145.648 13.4335 145.875 13.2081C146.101 12.9826 146.407 12.856 146.727 12.856C147.046 12.856 147.353 12.9826 147.579 13.2081C147.805 13.4335 147.932 13.7393 147.932 14.0582V16.9436C147.932 17.2624 147.805 17.5682 147.579 17.7937C147.353 18.0192 147.046 18.1458 146.727 18.1458Z" fill={color} />
          <path d="M21.623 130.275C20.5873 130.273 19.5946 129.862 18.8624 129.132C18.1303 128.401 17.7186 127.411 17.7178 126.378C17.7186 125.345 18.1303 124.354 18.8624 123.624C19.5946 122.893 20.5873 122.482 21.623 122.481C22.6589 122.482 23.6521 122.893 24.3846 123.623C25.1171 124.354 25.529 125.345 25.5298 126.378C25.5285 127.411 25.1165 128.401 24.3841 129.132C23.6517 129.862 22.6587 130.273 21.623 130.275ZM21.623 124.885C21.2264 124.886 20.8463 125.043 20.566 125.323C20.2858 125.603 20.1284 125.982 20.1284 126.378C20.1288 126.773 20.2864 127.152 20.5665 127.432C20.8467 127.712 21.2266 127.869 21.623 127.87C22.0197 127.87 22.4 127.712 22.6805 127.433C22.961 127.153 23.1187 126.773 23.1192 126.378C23.1194 126.182 23.0809 125.987 23.0058 125.806C22.9307 125.625 22.8205 125.46 22.6815 125.322C22.5426 125.183 22.3776 125.073 22.196 124.998C22.0143 124.922 21.8196 124.884 21.623 124.884V124.885Z" fill={color} />
          <path d="M150.182 54.8749C149.146 54.8736 148.153 54.4627 147.421 53.7321C146.689 53.0016 146.277 52.0112 146.275 50.978C146.276 49.9446 146.688 48.9538 147.421 48.2229C148.153 47.4921 149.146 47.0809 150.182 47.0796C151.218 47.0809 152.211 47.4918 152.943 48.2224C153.676 48.9529 154.088 49.9433 154.089 50.9765C154.088 52.0099 153.676 53.0007 152.944 53.7316C152.211 54.4624 151.218 54.8736 150.182 54.8749ZM150.182 49.4857C149.786 49.4865 149.406 49.644 149.125 49.9237C148.845 50.2034 148.687 50.5825 148.686 50.978C148.687 51.3736 148.845 51.7527 149.125 52.0324C149.406 52.3121 149.786 52.4696 150.182 52.4704C150.579 52.4696 150.959 52.3121 151.239 52.0324C151.52 51.7527 151.678 51.3736 151.678 50.978C151.678 50.5822 151.52 50.2027 151.24 49.9227C150.959 49.6426 150.579 49.4849 150.182 49.4841V49.4857Z" fill={color} />
          <path d="M47.9068 35.7953C46.8711 35.7941 45.8781 35.3831 45.1457 34.6526C44.4133 33.922 44.0013 32.9316 44 31.8985C44.0009 30.8651 44.4127 29.8742 45.1451 29.1433C45.8776 28.4125 46.8708 28.0013 47.9068 28C48.9426 28.0013 49.9356 28.4122 50.668 29.1428C51.4004 29.8733 51.8124 30.8637 51.8137 31.8969C51.8128 32.9303 51.401 33.9211 50.6686 34.652C49.9361 35.3829 48.9429 35.7941 47.9068 35.7953ZM47.9068 30.4061C47.5103 30.4069 47.1302 30.5644 46.8498 30.8441C46.5694 31.1238 46.4115 31.5029 46.4106 31.8985C46.4115 32.294 46.5694 32.6731 46.8498 32.9528C47.1302 33.2325 47.5103 33.39 47.9068 33.3908C48.3034 33.39 48.6834 33.2325 48.9638 32.9528C49.2443 32.6731 49.4022 32.294 49.403 31.8985C49.4026 31.5026 49.2449 31.1231 48.9645 30.8431C48.684 30.5631 48.3037 30.4053 47.9068 30.4045V30.4061Z" fill={color} />
          <path d="M129.907 84.7953C128.871 84.7941 127.878 84.3831 127.146 83.6526C126.413 82.922 126.001 81.9316 126 80.8985C126.001 79.8651 126.413 78.8742 127.145 78.1433C127.878 77.4125 128.871 77.0013 129.907 77C130.943 77.0013 131.936 77.4122 132.668 78.1428C133.4 78.8733 133.812 79.8637 133.814 80.8969C133.813 81.9303 133.401 82.9211 132.669 83.652C131.936 84.3829 130.943 84.7941 129.907 84.7953ZM129.907 79.4061C129.51 79.4069 129.13 79.5644 128.85 79.8441C128.569 80.1238 128.411 80.5029 128.411 80.8985C128.411 81.294 128.569 81.6731 128.85 81.9528C129.13 82.2325 129.51 82.39 129.907 82.3908C130.303 82.39 130.683 82.2325 130.964 81.9528C131.244 81.6731 131.402 81.294 131.403 80.8985C131.403 80.5026 131.245 80.1231 130.964 79.8431C130.684 79.5631 130.304 79.4053 129.907 79.4045V79.4061Z" fill={color} />
          <path d="M123.908 16.7029C123.653 16.703 123.405 16.6221 123.2 16.4721C120.881 14.789 118.466 13.2411 115.968 11.8363C115.689 11.68 115.484 11.4197 115.398 11.1128C115.312 10.8059 115.352 10.4775 115.508 10.1996C115.665 9.92177 115.926 9.71741 116.234 9.63146C116.542 9.5455 116.872 9.58495 117.15 9.74115C119.729 11.1921 122.22 12.7902 124.614 14.5277C124.742 14.6206 124.85 14.7378 124.933 14.8725C125.015 15.0072 125.071 15.1568 125.095 15.3128C125.12 15.4688 125.113 15.6282 125.076 15.7817C125.039 15.9352 124.972 16.08 124.879 16.2076C124.768 16.3606 124.622 16.4853 124.453 16.5714C124.284 16.6574 124.098 16.7025 123.908 16.7029Z" fill={color} />
          <path d="M57.5048 5.76758C57.2478 5.76755 56.9975 5.68554 56.7905 5.53357C56.5835 5.38159 56.4306 5.16759 56.3542 4.9228C56.3072 4.77197 56.2904 4.61338 56.3048 4.45609C56.3193 4.2988 56.3647 4.14589 56.4385 4.00611C56.5122 3.86633 56.6128 3.74241 56.7345 3.64143C56.8563 3.54045 56.9968 3.46438 57.1481 3.41759C64.4854 1.14513 72.1251 -0.00708716 79.808 3.27961e-05C85.8292 -0.00196243 91.8297 0.703946 97.6852 2.10315C97.9945 2.17773 98.2616 2.37124 98.4284 2.6415C98.5952 2.91176 98.6481 3.23684 98.5756 3.54584C98.5008 3.85431 98.3068 4.12078 98.0358 4.28714C97.7649 4.4535 97.439 4.50624 97.1292 4.43388C91.4579 3.07823 85.6463 2.39438 79.8145 2.39649C72.3752 2.38945 64.9776 3.50492 57.8728 5.70506C57.7542 5.74483 57.63 5.76593 57.5048 5.76758Z" fill={color} />
          <path d="M28.3345 22.8055C28.1702 22.8057 28.0076 22.7725 27.8567 22.7077C27.7058 22.6429 27.5698 22.548 27.457 22.4288C27.2376 22.197 27.1194 21.8879 27.1284 21.5692C27.1375 21.2506 27.273 20.9486 27.5052 20.7296C28.6993 19.6075 29.9432 18.5079 31.2015 17.4659C31.3234 17.3651 31.4641 17.2892 31.6154 17.2427C31.7667 17.1962 31.9258 17.1799 32.0835 17.1948C32.2411 17.2097 32.3942 17.2555 32.5341 17.3295C32.674 17.4035 32.7979 17.5044 32.8986 17.6262C32.9995 17.7479 33.0754 17.8882 33.122 18.0391C33.1685 18.19 33.1847 18.3486 33.1698 18.5058C33.1549 18.663 33.109 18.8157 33.0349 18.9552C32.9608 19.0948 32.8599 19.2184 32.7379 19.319C31.5165 20.3289 30.3112 21.3933 29.1557 22.4817C28.9329 22.6894 28.6395 22.805 28.3345 22.8055Z" fill={color} />
          <path d="M5.41444 86.715C5.12123 86.7145 4.83822 86.6075 4.61843 86.414C4.39863 86.2204 4.25709 85.9535 4.22034 85.6634C3.81426 82.4579 3.6109 79.23 3.61133 75.999C3.61093 72.7836 3.81271 69.5713 4.21556 66.3811C5.44487 56.592 8.59291 47.1398 13.4804 38.5628C13.5582 38.4254 13.6626 38.3045 13.7873 38.2073C13.912 38.1101 14.0547 38.0383 14.2073 37.9962C14.3598 37.954 14.5193 37.9423 14.6764 37.9617C14.8335 37.9811 14.9852 38.0312 15.1229 38.1092C15.2607 38.1869 15.3818 38.2909 15.4793 38.4153C15.5768 38.5397 15.6488 38.6821 15.691 38.8342C15.7333 38.9864 15.745 39.1454 15.7255 39.3021C15.7061 39.4588 15.6559 39.6101 15.5777 39.7475C10.8462 48.0507 7.79808 57.2011 6.60688 66.6776C6.217 69.7674 6.02168 72.8785 6.02196 75.9926C6.02151 79.1223 6.21849 82.249 6.61175 85.354C6.63202 85.5107 6.62113 85.6698 6.57965 85.8222C6.53818 85.9746 6.46695 86.1174 6.37006 86.2424C6.27317 86.3673 6.15248 86.4719 6.01498 86.5503C5.87747 86.6286 5.72582 86.6792 5.56871 86.6989C5.51767 86.7075 5.46615 86.7128 5.41444 86.715Z" fill={color} />
          <path d="M42.3469 142.016C42.1359 142.017 41.9285 141.961 41.7458 141.856C38.8103 140.164 35.9907 138.279 33.3054 136.214C33.18 136.117 33.0749 135.997 32.9961 135.86C32.9172 135.723 32.8662 135.572 32.8459 135.416C32.8256 135.259 32.8364 135.1 32.8777 134.948C32.9191 134.795 32.9901 134.652 33.0868 134.527C33.1834 134.402 33.3039 134.297 33.4412 134.219C33.5784 134.14 33.7299 134.089 33.8869 134.069C34.0439 134.049 34.2033 134.06 34.3561 134.101C34.509 134.142 34.6521 134.213 34.7775 134.309C37.378 136.309 40.1085 138.134 42.9511 139.774C43.0881 139.853 43.2083 139.958 43.3046 140.083C43.4009 140.208 43.4716 140.351 43.5125 140.504C43.5534 140.656 43.5638 140.815 43.543 140.971C43.5222 141.128 43.4707 141.279 43.3915 141.415C43.2857 141.598 43.1335 141.75 42.9501 141.856C42.7667 141.961 42.5586 142.017 42.3469 142.016Z" fill={color} />
          <path d="M79.8049 152C73.0414 152.005 66.3073 151.114 59.779 149.35C59.6262 149.309 59.483 149.238 59.3578 149.141C59.2325 149.045 59.1276 148.925 59.0489 148.788C58.9702 148.651 58.9194 148.5 58.8993 148.343C58.8793 148.187 58.8904 148.028 58.9321 147.875C58.9734 147.723 59.0444 147.58 59.1411 147.455C59.2378 147.33 59.3583 147.226 59.4956 147.147C59.633 147.069 59.7844 147.018 59.9414 146.998C60.0984 146.978 60.2578 146.989 60.4105 147.031C66.733 148.738 73.2548 149.6 79.8049 149.595C85.1112 149.597 90.4025 149.032 95.5881 147.909C95.7427 147.875 95.9025 147.872 96.0584 147.9C96.2142 147.928 96.363 147.986 96.4962 148.071C96.6294 148.157 96.7444 148.267 96.8345 148.397C96.9247 148.527 96.9883 148.673 97.0216 148.827C97.0894 149.139 97.0306 149.464 96.8579 149.733C96.6852 150.001 96.4129 150.189 96.1007 150.257C90.7468 151.417 85.2836 152.001 79.8049 152Z" fill={color} />
          <path d="M116.564 142.413C116.35 142.413 116.14 142.356 115.955 142.249C115.77 142.141 115.618 141.986 115.513 141.8C115.356 141.522 115.317 141.194 115.403 140.887C115.489 140.58 115.694 140.32 115.973 140.164C117.774 139.151 119.556 138.048 121.266 136.886C121.397 136.797 121.545 136.735 121.7 136.703C121.855 136.671 122.015 136.67 122.17 136.7C122.326 136.73 122.474 136.79 122.606 136.876C122.738 136.963 122.852 137.075 122.941 137.206C123.03 137.337 123.092 137.484 123.124 137.638C123.157 137.793 123.158 137.953 123.128 138.108C123.098 138.263 123.038 138.411 122.95 138.543C122.863 138.675 122.751 138.788 122.62 138.876C120.852 140.077 119.013 141.215 117.155 142.262C116.974 142.362 116.771 142.414 116.564 142.413Z" fill={color} />
          <path d="M135.442 127.353C135.145 127.352 134.859 127.242 134.639 127.043C134.402 126.829 134.26 126.53 134.244 126.211C134.228 125.893 134.339 125.581 134.554 125.344C142.785 116.264 148.617 105.28 151.523 93.3868C151.598 93.0768 151.794 92.8093 152.067 92.6431C152.34 92.4769 152.668 92.4256 152.979 92.5004C153.133 92.5376 153.278 92.6047 153.406 92.6979C153.533 92.791 153.642 92.9083 153.724 93.0432C153.806 93.1781 153.861 93.3278 153.886 93.4839C153.91 93.6399 153.903 93.7992 153.866 93.9527C150.864 106.236 144.841 117.579 136.341 126.957C136.227 127.082 136.089 127.182 135.934 127.25C135.779 127.319 135.612 127.353 135.442 127.353Z" fill={color} />
          <path d="M154.795 77.2016C154.636 77.2018 154.479 77.1708 154.333 77.1105C154.187 77.0501 154.054 76.9616 153.942 76.8499C153.83 76.7382 153.741 76.6056 153.681 76.4597C153.62 76.3137 153.589 76.1573 153.589 75.9993C153.589 73.7856 153.49 71.551 153.292 69.3598C153.131 67.5628 152.901 65.7546 152.611 63.9833C152.559 63.669 152.635 63.3471 152.821 63.0883C153.007 62.8295 153.289 62.655 153.604 62.6032C153.76 62.5773 153.92 62.5824 154.074 62.6182C154.228 62.654 154.374 62.7197 154.502 62.8118C154.631 62.9038 154.74 63.0202 154.824 63.1544C154.907 63.2886 154.964 63.4379 154.989 63.5938C155.29 65.4228 155.527 67.2903 155.693 69.145C155.897 71.4084 156 73.7151 156 75.9993C156 76.3182 155.873 76.624 155.647 76.8494C155.421 77.0749 155.114 77.2016 154.795 77.2016Z" fill={color} />
        </g>
        <path d="M30.5967 58H39.548V68.544H29.004V58H30.5967ZM57.996 79.0283H54.7468V75.7791H57.996V79.0283ZM54.3207 82.2775H51.0716V79.0283H54.3207V82.2775ZM50.6415 87H47.452V83.7508H50.6415V87ZM57.996 74.1226H54.7468V70.8734H57.996V74.1226ZM52.298 74.1226H50.6415V77.3718H47.452V70.8734H52.294V74.1226H52.298ZM38.5047 74.1226H35.2555V70.8734H38.5047V74.1226ZM32.1895 74.1226H29V70.8734H32.1895V74.1226ZM45.1226 62.8459H41.8734V58.004H45.1226V62.8459ZM45.1226 87H41.8734V79.0323H45.1226V87ZM45.1226 75.7791H41.8734V70.8734H45.1226V75.7791ZM45.1226 69.3444H41.8734V66.0952H45.1226V69.3444ZM30.5967 76.452H39.548V86.996H29.004V76.452H30.5967ZM36.2948 79.7012H32.1855V83.7468H36.2948V79.7012ZM49.0487 58H58V68.544H47.456V58H49.0487ZM54.7468 61.1895H50.7012V65.2988H54.7468V61.1895ZM36.2948 61.1895H32.1855V65.2988H36.2948V61.1895Z" fill={color} />
        <path fillRule="evenodd" clipRule="evenodd" d="M111.273 96.5168C111.748 96.996 111.748 97.8112 111.227 98.2904C110.752 98.7696 109.943 98.7696 109.468 98.2904C109.422 98.2437 96.4089 84.7801 95.0795 83.7751C94.9376 83.6319 94.5582 83.3426 94.4132 83.439C94.1295 83.6786 93.747 84.0147 93.4632 84.4441C93.1301 84.8766 92.797 85.4024 92.6088 86.0247V86.0714C92.0875 87.6987 92.325 89.9048 94.5088 92.1078L101.301 98.8629C101.585 99.1492 101.73 99.5817 101.634 100.011C101.634 100.011 98.8795 113.568 109.61 116.922C110.277 117.115 110.656 117.834 110.419 118.503C110.227 119.175 109.515 119.508 108.898 119.318C97.547 115.774 98.642 103.269 99.0677 100.204L92.7507 93.8813C89.7125 90.8631 89.4257 87.7018 90.2338 85.2593V85.2126C90.5176 84.3507 90.9463 83.5355 91.4213 82.9132C91.9426 82.2411 92.5132 81.7152 93.0345 81.3325C94.0801 80.6138 95.2183 80.4706 95.9801 81.0929C97.4052 82.2411 111.224 96.4701 111.27 96.5168H111.273ZM89.0384 113.466C88.9921 112.794 89.4672 112.171 90.1303 112.078C90.8428 111.981 91.4597 112.51 91.5553 113.179C91.7928 115.239 92.3251 116.829 93.5588 118.742C94.7926 120.659 96.7389 122.336 99.6383 123.245L100.542 123.531V128.706C100.542 129.424 99.9714 130 99.2589 130C98.5927 130 98.0251 129.424 98.0251 128.706V125.351C94.9376 124.203 92.8494 122.287 91.4707 120.13C89.95 117.784 89.2869 115.195 89 112.799L89.0384 113.466ZM123 128.516C123 129.235 122.429 129.81 121.717 129.81C121.004 129.81 120.483 129.235 120.483 128.516V93.7382L105.374 79.174C104.899 78.6948 104.852 77.8796 105.327 77.4004C105.802 76.8746 106.611 76.8746 107.132 77.3538L122.525 92.2509C122.809 92.4905 123 92.8265 123 93.2092V128.516Z" fill={color} />
        <path d="M100.666 112.204H69.7935C69.0324 112.205 68.2785 112.056 67.5751 111.766C66.8718 111.476 66.2327 111.05 65.6945 110.513C65.1563 109.977 64.7296 109.339 64.4388 108.637C64.148 107.936 63.9989 107.184 64 106.425V38.7788C63.9989 38.0196 64.148 37.2677 64.4388 36.5661C64.7296 35.8645 65.1563 35.227 65.6945 34.6902C66.2327 34.1534 66.8718 33.7278 67.5751 33.4377C68.2785 33.1477 69.0324 32.999 69.7935 33H100.658C101.418 33 102.171 33.1495 102.874 33.44C103.576 33.7306 104.214 34.1563 104.751 34.693C105.289 35.2298 105.715 35.8669 106.005 36.5679C106.295 37.269 106.444 38.0203 106.443 38.7788V92.5" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M99 102H64" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M75 74H96" stroke={color} strokeWidth="3" />
        <path d="M75 50H96" stroke={color} strokeWidth="3" />
        <path d="M75 79H80" stroke={color} strokeWidth="3" />
        <path d="M75 55H80" stroke={color} strokeWidth="3" />
        <path d="M99.1212 86H100C100.552 86 101 85.5523 101 85V69C101 68.4477 100.552 68 100 68H71C70.4477 68 70 68.4477 70 69V85C70 85.5523 70.4477 86 71 86H91.1364" stroke={color} strokeWidth="3" />
        <path d="M91 62H100C100.552 62 101 61.5523 101 61V45C101 44.4477 100.552 44 100 44H71C70.4477 44 70 44.4477 70 45V61C70 61.5523 70.4477 62 71 62H91.1364" stroke={color} strokeWidth="3" />
        <path d="M119.148 52.7346L117.33 50.9163C117.104 50.6903 116.976 50.384 116.976 50.0647C116.976 49.7454 117.102 49.4394 117.327 49.2139C117.553 48.9885 117.859 48.862 118.178 48.8625C118.497 48.8629 118.804 48.9901 119.03 49.2161L120.848 51.0343C121.074 51.2604 121.201 51.5667 121.202 51.886C121.202 52.2052 121.076 52.5113 120.85 52.7367C120.625 52.9622 120.319 53.0886 119.999 53.0882C119.68 53.0878 119.374 52.9606 119.148 52.7346Z" fill={color} />
        <path d="M126.193 59.7802L124.148 57.7347C123.922 57.5086 123.795 57.2023 123.794 56.883C123.794 56.5638 123.92 56.2577 124.146 56.0323C124.371 55.8068 124.677 55.6804 124.997 55.6808C125.316 55.6812 125.622 55.8084 125.848 56.0344L127.894 58.0799C128.12 58.306 128.247 58.6123 128.247 58.9316C128.248 59.2508 128.121 59.5569 127.896 59.7823C127.67 60.0078 127.364 60.1342 127.045 60.1338C126.726 60.1334 126.419 60.0062 126.193 59.7802Z" fill={color} />
        <path d="M117.228 59.7689C117.001 59.5428 116.874 59.2365 116.874 58.9172C116.873 58.598 117 58.2919 117.225 58.0665L119.039 56.2529C119.264 56.0274 119.57 55.901 119.89 55.9014C120.209 55.9018 120.515 56.029 120.741 56.2551C120.967 56.4811 121.095 56.7874 121.095 57.1067C121.095 57.426 120.969 57.732 120.743 57.9575L118.93 59.771C118.704 59.9965 118.398 60.1229 118.079 60.1225C117.76 60.1221 117.454 59.9949 117.228 59.7689Z" fill={color} />
        <path d="M124.028 52.968C123.802 52.742 123.675 52.4356 123.675 52.1164C123.674 51.7971 123.801 51.4911 124.026 51.2656L126.066 49.2253C126.292 48.9999 126.598 48.8734 126.917 48.8738C127.236 48.8743 127.543 49.0015 127.769 49.2275C127.995 49.4535 128.122 49.7599 128.123 50.0791C128.123 50.3984 127.997 50.7044 127.771 50.9299L125.731 52.9702C125.505 53.1956 125.199 53.3221 124.88 53.3217C124.561 53.3213 124.254 53.194 124.028 52.968Z" fill={color} />
      </svg>
    </>
  );
}