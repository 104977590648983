import { useContext, useState } from "react"
import { useTranslation } from "react-i18next";
import Helper from "../../util/helper";
import { AdditionalSignaturesContext } from "../../contexts/AdditionalSignaturesContext";

import './SignatureBox.css';
import { SignatureReadyIcon } from "../SvgComponents/SignatureReadyIcon/SignatureReadyIcon";
import { AdditionalSignatureModal } from "../Modals/AdditionalSignatureModal/AdditionalSignatureModal";
import { ReplicateAddiionalSignatureModal } from "../Modals/ReplicateAdditionalSignatureModal/ReplicateAdditionalSignatureModal";

export const SignatureBox = ({ boxData }) => {
    const { t } = useTranslation();
    const [openSignatureModal, setOpenSignatureModal] = useState(false);
    
    const [showConfirmationModalSignature, setShowConfirmationModal] = useState(false);

    const { isSignatureRecorded, addCompletedBox, checkIfBoxIsCompleted } = useContext(AdditionalSignaturesContext);

    const isReady = checkIfBoxIsCompleted(boxData);

    const getPositionStyles = () => {
        const { PosX, PosY, SizeX, SizeY } = boxData;
        return Helper.getElementPositionStylesNew(PosX, PosY, SizeX, '', SizeY);
    }

    const handleSignatureBoxClick = () => {
        if (!isReady) {
            if (isSignatureRecorded) {
                setShowConfirmationModal(true);
                return;
            }
            setOpenSignatureModal(true);
        }
    }

    const substract15PxToTopPosition = (topProperty) => {
        const leftValue = topProperty.split('px')[0];
    
        const newValue = parseInt(leftValue) - 15;
        
        return newValue;
    }

    const styleObject = getPositionStyles();

    const handleCloseConfirmationModal = () => {
        setShowConfirmationModal(false);
    }

    const handleReplicateSignature = () => {
        addCompletedBox(boxData);
        setShowConfirmationModal(false);
    }

    return (
        <>
            {
                isReady ? <SignatureReadyIcon positionStyling={{
                    ...styleObject,
                    top: substract15PxToTopPosition(styleObject.top)
                }} /> :
                <div style={styleObject} className={`signature-box`} onClick={handleSignatureBoxClick}>
                    {isSignatureRecorded ? t("additional_signatures.click_to_replicate_signature") : t("additional_signatures.click_to_sign")}
                </div>
            }
            <AdditionalSignatureModal boxData={boxData} openSignatureModal={openSignatureModal} setOpenSignatureModal={setOpenSignatureModal} />
            <ReplicateAddiionalSignatureModal showConfirmationModalSignature={showConfirmationModalSignature} handleCloseConfirmationModal={handleCloseConfirmationModal} handleReplicateSignature={handleReplicateSignature}  />
        </>
    );
}