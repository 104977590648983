import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EmailSignatureService from '../../services/EmailSignatureService';
import OauthApiService from '../../services/OauthService';
import SignatureService from '../../services/SignatureService';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../components/Loader/Loader';
import ThemeService from '../../services/ThemeService';
import { AuthContext } from '../../contexts/AuthContext';
import { Modal } from "antd";
import { Col, Row } from "react-bootstrap";
import "./CriiptoCallback.css";
import { AdditionalSignaturesFinalFlow } from '../../components/SignMethods/AdditionalSignaturesFinalFlow/AdditionalSignaturesFinalFlow';
import { AdditionalSignaturesContext } from '../../contexts/AdditionalSignaturesContext';

export const CriiptoCallback = () => {
  const [emailId, setEmailId] = useState(null);
  const [completeAdditionalSignaturesFlow, setCompleteAdditionalSignaturesFlow] = useState(false);
  const { setDocumentData } = useContext(AdditionalSignaturesContext);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const errorMessage = t("general.error_modal");

  const { setIsAuthenticated } = useContext(AuthContext);

  const getStateParams = () => {
    const windowUrl = window.location.search;
    const querystring = new URLSearchParams(windowUrl);

    let state = querystring.get("state");

    if (!state) {
      setError(t("general.error") + ". URL: " + window.location.href);
      return;
    }

    const decodedState = atob(state);

    const paramsArray = decodedState.split(',');

    return paramsArray;
  }

  useEffect(() => {
    const windowUrl = window.location.search;
    const querystring = new URLSearchParams(windowUrl);
    const code = querystring.get("code");

    if (!code) {
      setError(t("general.error") + ". URL: " + window.location.href);
      return;
    }

    const [emailId] = getStateParams();
    setEmailId(emailId);

    OauthApiService.getToken((emailId))
      .then(() => {
        ThemeService.setTheme(emailId);
        SignatureService.pendingSignatures(emailId)
          .then(async (signaturesResponse) => {
            const document = signaturesResponse.data[0];
            const body = {
              code
            };

            // Save data for additional signatures flow
            setDocumentData(document);

            EmailSignatureService.sendCriiptoCode(emailId, body)
                .then(() => {
                  // Allow navigation to next view
                  setIsAuthenticated(true);
                    let link = "/sign";
                    const signerDTO = document?.SignerDTO;
                    const signatureBoxes = signerDTO?.SignatureBoxes;
                    const isSignatureBoxesFlow = !!signatureBoxes;

                    if (document.SignerDTO.AttachIdDocImages) {
                      link = "/attach-images";
                    } else {
                      if (isSignatureBoxesFlow) return setCompleteAdditionalSignaturesFlow(true);
                    }

                navigate(link, {
                  state: {
                    documents: document,
                    emailId,
                    otpCode: ''
                  }
                });
              })
              .catch((e) => {
                setError(true);
              })
          })
      })
      .catch((e) => {
        const statusCode = e?.response?.status;
        if (statusCode === 400) {
          setError(true);
          return;
        }
        if (statusCode === 401) {
          setError(true);
          return;
        }
        setError(true);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleErrorModal = () => {
    setError(null);
    navigate('/');
  }

  return <>
    {error && error}
    {!error && <Loader />}
    <Modal
      title={t('document_warning.title')}
      footer={null}
      open={error}
    >
      <div className="modal-container">
        <p className="nu-input-label">{errorMessage}</p>
        <Col className="action-buttons mobile-button">
          <Row>
            <Col md={3} xs={3}></Col>
            <Col md={6} xs={6}>
              <button
                type="button"
                className="btn btn-primary criipto-modal"
                onClick={() => handleErrorModal()}
              >
                <span className="cancel-text">{t("general.ok")}</span>
                <svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 7.7L8.54098 14L21 2" stroke="#151A35" stroke-width="3" />
                </svg>
              </button>
            </Col>
            <Col md={3} xs={3}></Col>
          </Row>
        </Col>
      </div>
    </Modal>
    {completeAdditionalSignaturesFlow && <AdditionalSignaturesFinalFlow emailId={emailId} />}
  </>;
}