import { useEffect, useRef, useState } from "react";
import "./QRLoader.css";
import EmailSignatureService from "../../services/EmailSignatureService";
import { Loader } from "../Loader/Loader";
import { Container, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { POLLING_INTERVAL_ID, QR_EXPIRATION_TIME } from "../../constants/GeneralConstants";
import { useNavigate } from "react-router-dom";

export const QRLoader = ({ signerGuid, setReceivedGraphData, additionalSignature }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loadingQRImage, setLoadingQR] = useState(false);
  const [qrData, setQRData] = useState({});
  const [showQR, setShowQR] = useState(true);
  const { qr64Data, qrToken } = qrData;
  const abortControllerRef = useRef(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    getQRData();
    return () => {
      const id = sessionStorage.getItem(POLLING_INTERVAL_ID);
      clearInterval(id);
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      sessionStorage.removeItem(POLLING_INTERVAL_ID);
    }
  }, []);

  const getQRData = () => {
    setLoadingQR(true);
    const body = { SignersGuid: [signerGuid] };

    EmailSignatureService.getQRData(body)
      .then((response) => {
        const { QRCodeImage, Token } = response.data;
        setQRData({ qr64Data: QRCodeImage, qrToken: Token });
        pollingQRGraphData(Token);
        startTimer();
        const id = setInterval(() => pollingQRGraphData(Token), 18 * 1000);
        sessionStorage.setItem(POLLING_INTERVAL_ID, id);
      })
      .catch(e => {
        const error = e.response.data;
        if (error.toLowerCase().includes('invalid authentication')) {
          navigate('/expired-personal-code');
        }
      })
      .finally(() => setLoadingQR(false));
  };

  const pollingQRGraphData = (token) => {
    if (sessionStorage.getItem("COMPLETED_ADDITIONAL_SIGNATURE")) return;

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (abortControllerRef.current) {
      const previousController = abortControllerRef.current;
      timeoutRef.current = setTimeout(() => {
        previousController.abort();
      }, 1000);
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;

    EmailSignatureService.pollingQR(token, controller.signal)
      .then(response => {
        const { SignatureImage, SignatureRawData } = response.data;
        setReceivedGraphData({
          SignatureImage,
          SignatureRawData,
        });
      })
      .catch(error => {
        console.error("Polling error:", error);
      });
  };

  const startTimer = () => {
    setTimeout(() => {
      setShowQR(false);
      const id = sessionStorage.getItem(POLLING_INTERVAL_ID);
      clearInterval(id);
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    }, QR_EXPIRATION_TIME);
  };

  const resetQR = () => {
    setShowQR(true);
    setQRData({});
    getQRData();
  };

  return (
    <>
      {loadingQRImage ? (
        <Loader />
      ) : (
        <Container className={additionalSignature ? "qr-container-reverse" : "qr-container"}>
          {showQR ? (
            <>
              <img
                src={`data:image/png;base64,${qr64Data}`}
                className="qr-image"
                alt="QR Code"
              />
              <p className="qr-instructions-title">{t("sign.better_experience")}</p>
              <p>{t("sign.you_can_scan")}</p>
            </>
          ) : (
            <span className="qr-image" style={{ marginTop: "15px" }}>
              {t("sign.qr.update_part_1")}{" "}
              <a href="#" onClick={(e) => { e.preventDefault(); resetQR(); }} >
                {t("sign.qr.update_part_2")}
              </a>{" "}
              {t("sign.qr.update_part_3")}
            </span>
          )}
        </Container>
      )}
    </>
  );
};
